import React from "react"
import Calendar from "react-calendar"

import "react-calendar/dist/Calendar.css"
import "./index.css"

function isSameDay(d1, d2) {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  )
}

const VisitCalendar = ({ dates }) => {
  return (
    <Calendar
      tileClassName={({ date, view }) => {
        // Add class to tiles in month view only
        if (date)
          if (view === "month") {
            // Check if a date React-Calendar wants to check is on the list of dates to add class to
            if (dates.find(({ date: dDate }) => isSameDay(dDate, date))) {
              return "active"
            }
          }
      }}
      locale="fr"
      minDate={new Date()}
      value={null}
      onClickMonth={(value, event) =>
        console.log("Clicked month: ", value.getMonth())
      }
    />
  )
}

export default VisitCalendar
