import React from "react"
import { graphql, Link } from "gatsby"
import { getImage, withArtDirection } from "gatsby-plugin-image"

import Container from "~components/Container"
import Layout from "../layouts"
import SEO from "~components/SEO"
import Hero from "~components/Hero"
import BreadCrumb from "~components/Breadcrumb"

import VisitCalendar from "~components/VisitCalendar"
import { toHoursAndMinutes } from "~utils/datetime"
import InfoSection from "~components/InfoSection"

const Visites = ({ pageContext, data }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  const { bannerImageMobile, bannerImageDesktop, visits } = data

  const images = withArtDirection(getImage(bannerImageMobile), [
    {
      media: "(min-width: 768px)",
      image: getImage(bannerImageDesktop),
    },
  ])

  const allVisitsByDate = visits.edges.reduce((acc, visit) => {
    const {
      node: { visit_dates: visitDates },
    } = visit
    const dates = visitDates.map(visitDate => ({
      date: new Date(visitDate.date),
      organisation: visitDate.organisation,
      visit,
    }))
    return [...acc, ...dates]
  }, [])

  const futureVisitsByDate = allVisitsByDate
    .filter(visitByDate => visitByDate.date.getTime() > Date.now())
    .sort((a, b) => a.date.getTime() - b.date.getTime())

  return (
    <Layout>
      <SEO title="Visites" />

      <Hero background={images} />

      {data?.infoMessage?.active && (
        <InfoSection message={data?.infoMessage?.message} />
      )}

      <Container>
        <section className="tw-mt-8">
          <h1 className="h1 tw-text-center">Programme des visites</h1>

          <p className="subtitle tw-text-center">
            Découvrez les prochaines visites programmées
          </p>

          <hr className="tw-mt-8" />

          <BreadCrumb crumbs={crumbs} crumbSeparator=" > " />
        </section>

        <section className="tw-mt-8">
          <VisitCalendar dates={futureVisitsByDate} />

          {futureVisitsByDate.map(
            ({ date, organisation, visit: { node } }, index) => {
              const { slug, title, duration } = node
              const { hours, minutes } = toHoursAndMinutes(duration)
              return (
                <article key={index} className="tw-mb-6">
                  <h2 className="tw-font-bold">
                    📅{" "}
                    {new Intl.DateTimeFormat("fr-FR", {
                      weekday: "long",
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                    })
                      .format(new Date(date))
                      .replace(/^\w/, $0 => $0.toUpperCase())}{" "}
                    <span>
                      , 🕒 {hours > 0 && `${hours}h`}
                      {minutes !== 0 &&
                        `${minutes.toString().padStart(2, "0")}`}
                    </span>
                  </h2>

                  <p>
                    <Link to={`/visites/${slug}`}>{`${title}${
                      organisation ? " (complet)" : ""
                    }`}</Link>
                  </p>
                </article>
              )
            }
          )}
        </section>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ProgrammePageQuery {
    infoMessage: strapiInfoMessage(active: { eq: true }) {
      active
      message
    }
    visits: allStrapiVisit {
      edges {
        node {
          id
          strapiId
          title
          slug
          summary
          thumbnail {
            alternativeText
            url
          }
          duration
          visit_dates {
            date
            organisation
          }
        }
      }
    }
    bannerImageMobile: file(
      relativePath: { eq: "banners/visit-page-banner-mobile.webp" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
        )
      }
    }
    bannerImageDesktop: file(
      relativePath: { eq: "banners/visit-page-banner.webp" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
        )
      }
    }
  }
`

export default Visites
